import {
    ALL_ROUTES,
    API_GATEWAY_URL,
    FETCH_ALL_REQUEST_COUNT,
    FETCH_ALL_HEALTH_SYSTEMS,
    FETCH_ALL_HEALTH_SYSTEM_WISE_SUMMARY_COUNT,
    FETCH_ALL_REQUESTS,
    DOWNOAD_PDF_USING_SIGNED_URL,
    FETCH_JOB_SUMMARY,
    CHECK_LOGIN
} from '../constants.js';

import axios from 'axios'
import _ from 'lodash';

/**
 * Function used to clear cookies according to given status.
 * This will execute if status is 401
 */
export function handleUnauthorizedRequest(status) {
    if (status === 401 || status === 440) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = ALL_ROUTES.LOGIN;
    } else {
        return;
    }
}

export const downloadFileFromBase64 = (base64code, filename) => {
    var tempLink = document.createElement('a');
    tempLink.setAttribute('href', 'data:text/plain;base64,' + base64code);
    tempLink.download = filename;
    document.body.appendChild(tempLink);
    tempLink.click();
    tempLink.remove();
}

/**
 * Function used to fetch all Request Counts
 * @returns Request Counts
 */
export const fetchAllRequestCount = (fromdate, todate, selectedFilterType, isFilterByCreatedDate) => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        let params = {
            filtertype: selectedFilterType,
            isFilterByCreatedDate: isFilterByCreatedDate
        }
        if (fromdate) {
            params.fromdate = fromdate
        }
        if (todate) {
            params.todate = todate
        }
        axios({
            method: 'GET',
            url: `${API_GATEWAY_URL}${FETCH_ALL_REQUEST_COUNT}`,
            'headers': {
                'content-type': 'application/json',
                'Authorization': token,
                'request-user-id': 3
            },
            params: params
        })
            .then(async response => {
                let requestCountList = [];
                let healthSystemwiseRequestCountList = []
                let healthSystemwiseSummaryList = []
                if (response && response.data && response.data.success && response.data.success.requestCountList && response.data.success.requestCountList.length) {
                    requestCountList = response.data.success.requestCountList
                }

                if (response && response.data && response.data.success && response.data.success.healthSystemwiseRequestCountList && response.data.success.healthSystemwiseRequestCountList.length) {
                    healthSystemwiseRequestCountList = response.data.success.healthSystemwiseRequestCountList
                }

                if (response && response.data && response.data.success && response.data.success.healthSystemwiseSummaryList && response.data.success.healthSystemwiseSummaryList.length) {
                    healthSystemwiseSummaryList = response.data.success.healthSystemwiseSummaryList
                }
                resolve({requestCountList: requestCountList, healthSystemwiseRequestCountList: healthSystemwiseRequestCountList, healthSystemwiseSummaryList: healthSystemwiseSummaryList })
            })
            .catch(error => {
                if (error && error.response && error.response.status) {
                    handleUnauthorizedRequest(error.response.status);
                } else {
                    const fhirVersionList = [];
                    resolve(fhirVersionList)
                }
            })
    });
}

/**
 * Function used to fetch all Health Systems
 * @returns Health Systems
 */
export const fetchAllHealthSystems = (year) => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        axios({
            method: 'GET',
            url: `${API_GATEWAY_URL}${FETCH_ALL_HEALTH_SYSTEMS}`,
            'headers': {
                'content-type': 'application/json',
                'Authorization': token,
                'request-user-id': 3
            }
        })
            .then(async response => {
                let apiResponse = [];
                if (response && response.data && response.data.success && response.data.success.healthSystemList && response.data.success.healthSystemList.length) {
                    _.each(response.data.success.healthSystemList, function (data) {
                        apiResponse.push({ ...data, value: data.facilityid, label: `${data.name} - ${data.facilityid}` });
                    })
                }
                resolve(apiResponse)
            })
            .catch(error => {
                if (error && error.response && error.response.status) {
                    handleUnauthorizedRequest(error.response.status);
                } else {
                    const fhirVersionList = [];
                    resolve(fhirVersionList)
                }
            })
    });
}

/**
 * Function used to fetch all Health Systemwise summary count
 * @returns Health Systemwise summary count
 */
export const fetchAllHealthSystemwiseSummaryCount = (vendorid) => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        axios({
            method: 'GET',
            url: `${API_GATEWAY_URL}${FETCH_ALL_HEALTH_SYSTEM_WISE_SUMMARY_COUNT}`,
            'headers': {
                'content-type': 'application/json',
                'Authorization': token,
                'request-user-id': 3
            },
            params: {
                vendorid: vendorid
            }
        })
            .then(async response => {
                let apiResponse = [];
                if (response && response.data && response.data.success && response.data.success.summaryList && response.data.success.summaryList.length) {
                    apiResponse = response.data.success.summaryList
                }
                resolve(apiResponse)
            })
            .catch(error => {
                if (error && error.response && error.response.status) {
                    handleUnauthorizedRequest(error.response.status);
                } else {
                    const fhirVersionList = [];
                    resolve(fhirVersionList)
                }
            })
    });
}

/**
 * Function used to fetch all Requests
 * @returns Requests
 */
export const fetchAllRequests = (vendorid, searchFields = null, page, rowsPerPage) => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        let data = {
            vendorid: vendorid,
            pagenumber: page,
            pagesize: rowsPerPage
        }

        if (searchFields) {
            _.each(searchFields, function(field) {
                if (field.value && field.value !== "") {
                    if (field.type === "select") {
                        data[field.responseValue] = field.value.value
                    } else {
                        data[field.responseValue] = field.value
                    }
                }
            })
        }

        axios({
            method: 'POST',
            url: `${API_GATEWAY_URL}${FETCH_ALL_REQUESTS}`,
            'headers': {
                'content-type': 'application/json',
                'Authorization': token,
                'request-user-id': 3
            },
            data: data
        })
            .then(async response => {
                let requestList = [];
                let apiResponse = [];
                if (response && response.data && response.data.success && response.data.success.requests && response.data.success.requests.length) {
                    apiResponse = response.data.success.requests
                }
                _.each(apiResponse, function (data) {
                    requestList.push({
                        ...data,
                        showOutputDetailsLoader: false,
                        showJobSummaryLoader: false
                    });
                })
                resolve({requestList: requestList, totalRequests: response.data.success.totalRequests})
            })
            .catch(error => {
                if (error && error.response && error.response.status) {
                    handleUnauthorizedRequest(error.response.status);
                } else {
                    const fhirVersionList = [];
                    resolve(fhirVersionList)
                }
            })
    });
}

/**
 * Function used to Download using signed URL
 * @returns Signed URL
 */
export const downloadRADocumentUsingSignedURL = (requestid) => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        axios({
            method: 'POST',
            url: `${API_GATEWAY_URL}${DOWNOAD_PDF_USING_SIGNED_URL}`,
            'headers': {
                'content-type': 'application/json',
                'Authorization': token,
                'request-user-id': 3
            },
            data: {
                requestid: requestid
            }
        })
            .then(async response => {
                let apiResponse = [];
                if (response && response.data && response.data.success && response.data.success.patientmedicalrecord && response.data.success.patientmedicalrecord.mrdetails && response.data.success.patientmedicalrecord.mrdetails.length && response.data.success.patientmedicalrecord.mrdetails[0].file) {
                    apiResponse = response.data.success.patientmedicalrecord.mrdetails[0].file
                }
                resolve(apiResponse)
            })
            .catch(error => {
                if (error && error.response && error.response.status) {
                    handleUnauthorizedRequest(error.response.status);
                } else {
                    const fhirVersionList = [];
                    resolve(fhirVersionList)
                }
            })
    });
}

/**
 * Function used to Fetch Job Summary
 * @returns Job Summaru
 */
export const fetchJobSummary = (requestid, requesterrefid) => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        axios({
            method: 'POST',
            url: `${API_GATEWAY_URL}${FETCH_JOB_SUMMARY}`,
            'headers': {
                'content-type': 'application/json',
                'Authorization': token,
                'request-user-id': 3
            },
            data: {
                requestid: requestid
            }
        })
            .then(async response => {
                let apiResponse = [];
                if (response && response.data && response.data.success && response.data.success.errordetails && response.data.success.errordetails.length) {
                    for(const d of response.data.success.errordetails) {
                        apiResponse.push({
                            requesterrefid: requesterrefid,
                            ...d
                        })
                    }
                }
                resolve(apiResponse)
            })
            .catch(error => {
                if (error && error.response && error.response.status) {
                    handleUnauthorizedRequest(error.response.status);
                } else {
                    const fhirVersionList = [];
                    resolve(fhirVersionList)
                }
            })
    });
}

/**
 * Function used to Download using signed URL
 * @returns Signed URL
 */
export const checkLogin = (firstname, lastname, username, email, loginwithsso) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `${API_GATEWAY_URL}${CHECK_LOGIN}`,
            'headers': {
                'content-type': 'application/json',
                'request-user-id': 3
            },
            data: {
                "firstname": firstname,
                "lastname": lastname,
                "username": username,
                "email": email,
                "loginwithsso": loginwithsso
            }
        })
            .then(async response => {
                const token = (response && response.data && response.data.success && response.data.success.token) ? response.data.success.token : null
                
                resolve(token)
            })
            .catch(error => {
                if (error && error.response && error.response.status) {
                    handleUnauthorizedRequest(error.response.status);
                } else {
                    const fhirVersionList = [];
                    resolve(fhirVersionList)
                }
            })
    });
}