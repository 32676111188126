import React from 'react';
import LandingPage from './containers/LandingPage/LandingPage';
import Dashboard from './containers/Dashboard/Dashboard';
import Login from './containers/Login/Login';
import Layout from './containers/Layout/Layout';
import NotFoundPage from './containers/NotFoundPage/NotFoundPage';
import HealthSystemwiseSummary from './containers/HealthSystemwiseSummary/HealthSystemwiseSummary';

import {
  ALL_ROUTES
} from '../src/constants';

import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from '@azure/msal-react';

const loginRequest = {
  scopes: ['user.read']
}

const WrappedView = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleRedirect = () => {
    console.log("Hi....!")
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: 'create'
      })
      .catch((error) => console.log("Error Found :::: ", error))
  }

  return (
    <div className='App'>
      {
        localStorage.getItem("token") ? (
          <Router>
              <Switch>
                <Route exact path={ALL_ROUTES.LOGIN} component={() => LoginPage(instance)}></Route>
                <Route exact path={ALL_ROUTES.LANDING_PAGE} component={() => LandingPageWarpper()}></Route>
                <Route exact path={ALL_ROUTES.DASHBOARD} component={() => DashboardPageWrapper()}></Route>
                <Route exact path={ALL_ROUTES.HEALTH_SYSTEMWISE_SUMMARY_PAGE} component={() => HealthSystemwiseSummaryPageWrapper()}></Route>
                <Route component={NotFoundPage}></Route>
              </Switch>
            </Router>
        ) : (
          <>
            <AuthenticatedTemplate>
              {
                (activeAccount) ? (
                  <Router>
                    <Switch>
                      <Route exact path={ALL_ROUTES.LOGIN} component={() => LoginPage()}></Route>
                      <Route exact path={ALL_ROUTES.LANDING_PAGE} component={() => LandingPageWarpper()}></Route>
                      <Route exact path={ALL_ROUTES.DASHBOARD} component={() => DashboardPageWrapper()}></Route>
                      <Route exact path={ALL_ROUTES.HEALTH_SYSTEMWISE_SUMMARY_PAGE} component={() => HealthSystemwiseSummaryPageWrapper()}></Route>
                      <Route component={NotFoundPage}></Route>
                    </Switch>
                  </Router>
                ) : null
              }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate> 
              <Router>       
                <Login handleRedirect={handleRedirect} />
              </Router>
            </UnauthenticatedTemplate>
          </>
        )
      }      
    </div>
  )
}

const ProtectedRoute = (props) => {
  const token = localStorage.getItem('token');

  if (token) {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>
    )
  } else {
    window.location.replace(ALL_ROUTES.LOGIN)
  }
}

const LandingPageWarpper = () => {
  return (
    <ProtectedRoute>
      <Layout>
        <LandingPage />
      </Layout>
    </ProtectedRoute>
  )
}

const HealthSystemwiseSummaryPageWrapper = () => {
  return (
    <ProtectedRoute>
      <Layout>
        <HealthSystemwiseSummary />
      </Layout>
    </ProtectedRoute>
  )
}

const DashboardPageWrapper = () => {
  return (
    <ProtectedRoute>
      <Layout>
        <Dashboard />
      </Layout>
    </ProtectedRoute>
  )
}

const LoginPage = async (instance) => {
  const token = localStorage.getItem('token');
  if (token) {
    window.location.replace(ALL_ROUTES.LANDING_PAGE);
  } else {
    const accounts = instance.getActiveAccount();

    if (accounts.length > 0) {
      await instance.logout({
        account: accounts[0]
      })
    }
    return (
      <Login />
    )
  }
}

// function App() {
//   return (
//     <div className="App">
//       <Router>
//         <Switch>
//           <Route exact path={ALL_ROUTES.LOGIN} component={() => LoginPage()}></Route>
//           <Route exact path={ALL_ROUTES.LANDING_PAGE} component={() => LandingPageWarpper()}></Route>
//           <Route exact path={ALL_ROUTES.DASHBOARD} component={() => DashboardPageWrapper()}></Route>
//           <Route component={NotFoundPage}></Route>
//         </Switch>
//       </Router>
//     </div>
//   );
// }

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <WrappedView />
    </MsalProvider>
  )
};

export default App;